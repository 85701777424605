<template>
  <div class="content container">
    <PageHeader :title="`Bem-vindo(a) de volta ${firstName}!`" />
    <div class="page-content">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <span class="m-t-10 d-inline-block">{{$t('acc-settings.choose')}}</span>
              <router-link
                to="/accounts/create"
                class="btn btn-success float-right"
                tag="button"
              ><i class="fas fa-plus color-white"></i> <span class="hide-mobile">{{$t('acc-settings.create.new')}}</span></router-link>
            </div>
            <div class="card-body account-list block-el">
              <div v-if="loaded" class="row">
                <div
                  v-for="account in accounts"
                  :key="account.id"
                  class="col-lg-6"
                  @click.prevent="switchAccount(account.id)"
                >
                  <div class="card card-border-primary">
                    <div class="card-header" style="color: #fff;">{{ account.name }}</div>
                    <div class="card-body">
                      <p><strong class="weight-500">ID:</strong> {{ account.id }}</p>
                      <p class="weight-500">
                        <template v-if="account.type == 'trial'">{{$t('acc-settings.trial')}}</template>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="qt-block-ui" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AccountService from '@/services/account.service';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'Accounts',
  components: {
    PageHeader,
  },
  data() {
    return {
      loaded: false,
      firstName: this.$store.state.auth.user.name.split(' ')[0],
      accounts: [],
    };
  },
  mounted() {
    this.fetchAccounts();
  },
  methods: {
    fetchAccounts() {
      AccountService.getAccounts().then(
        (response) => {
          this.accounts = response;
          this.loaded = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    switchAccount(accountId) {
      this.$store.dispatch('account/select', accountId).then(
        () => {
          this.$router.push('/');
        },
        (error) => {
          console.log(error);
        },
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.account-list {
  min-height: 250px;
}
.account-list .card {
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 40px 0 rgba(18, 106, 211, 0.7),
      0 2px 9px 0 rgba(18, 106, 211, 0.6);
  }
}
</style>
